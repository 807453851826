<i18n>
{
  "nl-BE": {
    "enter_address": "Vul het adres hier in",
    "not_found": "Het adres staat niet in de lijst",
    "enter_parcel": "Enter parcel id here",
    "parcel_not_found": "The parcel id is not in the list"
  },
  "en-BE": {
    "enter_address": "Enter address here",
    "not_found": "The address is not in the list",
    "enter_parcel": "Enter parcel id here",
    "parcel_not_found": "The parcel id is not in the list"
  },
  "fr-BE": {
    "enter_address": "Entrer une adresse ici",
    "not_found": "L'adresse n'est pas dans la liste",
    "enter_parcel": "Enter parcel id here",
    "parcel_not_found": "The parcel id is not in the list"
  }
}
</i18n>

<template>
    <b-field expanded>
        <p class="control mb-1">
            <b-button
                type="submit"
                :size="size"
                class="button is-primary"
                icon-right="magnify"
                @click="$emit('selectedItem', selected)"
            ></b-button>
        </p>
        <b-autocomplete
            :size="size"
            :placeholder="placeholder || addressMessage"
            clearable
            expanded
            field="full_address"
            :data="data"
            :loading="isFetching"
            :disabled="readOnly"
            @typing="getAsyncData"
            @select="(option) => (selected = option)"
        >
            <template slot-scope="props">
                <div class="media is-flex is-align-items-center">
                    <div class="media-left">
                        <b-icon icon="map-marker" type="is-primary" :size="size"></b-icon>
                    </div>
                    <div class="media-content">
                        {{
                            hasAddress
                                ? props.option.full_address
                                : props.option.parcel_name
                        }}
                    </div>
                </div>
            </template>
        </b-autocomplete>
    </b-field>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
    props: {
        size: {
            type: String,
            default: 'is-medium',
        },
        placeholder: {
            type: String,
            default: '',
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        hasAddress: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            selected: '',
            isFetching: false,
            data: [],
        }
    },
    computed: {
        addressMessage() {
            return this.hasAddress ? this.$t('enter_address') : this.$t('enter_parcel')
        },
        notFoundItem() {
            if (this.hasAddress) {
                return {
                    building_id: null,
                    full_address: this.$t('not_found'),
                }
            } else {
                return {
                    parcel_id: null,
                    parcel_name: this.$t('parcel_not_found'),
                }
            }
        },
    },
    watch: {
        selected(val) {
            this.$emit('selectedItem', val)
        },
    },
    methods: {
        getAsyncData: debounce(function (name) {
            if (!name.length) {
                this.data = [this.notFoundItem]
                return
            }
            this.isFetching = true
            this.$axios
                .get(
                    `/autocomplete?term=${name}` +
                        (this.hasAddress ? '' : '&expected_output=parcel_name')
                )
                .then(({ data }) => {
                    this.data = []
                    data.results.forEach((item) => this.data.push(item))
                    this.data.push(this.notFoundItem)
                })
                .catch((error) => {
                    this.data = [this.notFoundItem]
                    throw error
                })
                .finally(() => {
                    this.isFetching = false
                })
        }, 500),
    },
}
</script>

<style lang="scss" scoped>
.autocomplete-content {
    line-height: 0.8rem;
}
</style>
